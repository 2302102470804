import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDiagnostics } from "../../redux/actions";
import modelDiagnostics from "../../models/diagnostics";
import ReactTable from "react-table";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import firebase from "../../firebase";
class ScreenLogin extends React.Component {
  constructor() {
    super();
    this.audioPlayer = React.createRef();
  }
  componentDidMount() {
    this.props.fetchDiagnostics();
    firebase.connectNotifications(() => {
      this.audioPlayer.audioEl.play();
    });
  }

  render() {
    const columns = [
      {
        Header: "Date",
        accessor: "createdAt",
        width: 150,
        Cell: row => {
          return moment(row.value).format("DD/MM HH:mm");
        }
      },
      {
        Header: "Client",
        accessor: "customer",
        width: 200,
        Cell: row => {
          return (
            <span>
              {row.value.firstname} {row.value.lastname}
            </span>
          );
        }
      },
      {
        Header: "Steps",
        accessor: "steps",
        style: { whiteSpace: "unset" },
        Cell: row => {
          return (
            <ul>
              {row.value.map(p => {
                return <li key={p.nextStepId}>{p.content}</li>;
              })}
            </ul>
          );
        }
      },
      {
        Header: "Notes",
        accessor: "notes",
        style: { whiteSpace: "unset" },
        width: 200
      }
    ];
    return (
      <section>
        <ReactAudioPlayer
          src="../../assets/audio/dingding.wav"
          controls
          ref={element => {
            this.audioPlayer = element;
          }}
        />
        <h1>Diagnostics</h1>
        <Link to={`${this.props.match.url}/form`}>Nouveau</Link>
        <ReactTable data={this.props.diagnostics} columns={columns} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    diagnostics: modelDiagnostics.fromNormalizedtoArray(state.diagnostics.items)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDiagnostics: () => dispatch(fetchDiagnostics())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenLogin);
