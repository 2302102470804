import firebase from "firebase";
import config from "./config";

export default {
  init: () => {
    firebase.initializeApp(config.firebase);
  },
  /**
   * callback: function(user){}
   */
  onAuthStateChanged: callback => {
    return firebase.auth().onAuthStateChanged(user => {
      callback(user ? user.toJSON() : null);
    });
  },
  signIn: (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        return res.user.toJSON();
      });
  },
  signOut: () => {
    return firebase.auth().signOut();
  },
  add: () => {
    const db = firebase.firestore();
    db.collection(config.db.rootCollection)
      .doc(config.db.envCollection)
      .collection("diagnostics")
      .add({
        first: "Ada",
        last: "Lovelace",
        born: 1815
      })
      .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  },
  fetchDiagnostics: () => {
    const db = firebase.firestore();
    return db
      .collection(config.db.rootCollection)
      .doc(config.db.envCollection)
      .collection("diagnostics")
      .get()
      .then(function(querySnapshot) {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.id] = doc.data();
        });
        return items;
      });
  },
  postDiagnostic: diagnostic => {
    const db = firebase.firestore();
    let id = null;
    return db
      .collection(config.db.rootCollection)
      .doc(config.db.envCollection)
      .collection("diagnostics")
      .add(diagnostic)
      .then(docRef => {
        id = docRef.id;
        return db
          .collection(config.db.rootCollection)
          .doc(config.db.envCollection)
          .collection("diagnostics")
          .doc(id)
          .set(
            {
              id
            },
            { merge: true }
          );
      })
      .then(() => {
        return id;
      });
  },
  connectNotifications: callback => {
    const ref = firebase.database().ref("notifications/");
    ref.on("child_added", function(snapshot) {
      console.log("child_added", snapshot.val());
      callback();
    });
  }
};
