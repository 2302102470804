import * as ActionTypes from "../ActionTypes";

const initialState = {
  isFetching: false,
  itemHasBeenSubmitted: false,
  items: [],
  current: { id: false, steps: [] }
};
export default function inputs(state = initialState, action) {
  switch (action.type) {
    /**
     * INIT
     */
    case ActionTypes.INIT_DIAGNOSTIC:
      return { ...state, current: action.item, itemHasBeenSubmitted: false };

    case ActionTypes.TEST_ACTION:
      return { ...state };

    /**
     * FETCH
     */
    case ActionTypes.FETCH_DIAGNOSTICS_REQUEST:
      return { ...state, isFetching: true, itemHasBeenSubmitted: false };

    case ActionTypes.FETCH_DIAGNOSTICS_SUCCESS:
      return { ...state, items: action.items, isFetching: false };

    case ActionTypes.FETCH_DIAGNOSTICS_FAILURE:
      return { ...state, isFetching: false };

    /**
     * SUBMIT
     */
    case ActionTypes.SUBMIT_DIAGNOSTIC_STEP:
      state.current.steps.push(action.step);
      return { ...state };

    case ActionTypes.SUBMIT_DIAGNOSTIC:
      state.current = action.item;
      return { ...state };

    /**
     * POST
     */
    case ActionTypes.POST_DIAGNOSTIC_REQUEST:
      return { ...state, isFetching: true };

    case ActionTypes.POST_DIAGNOSTIC_SUCCESS:
      state.items[action.item.id] = action.item;
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state };

    case ActionTypes.POST_DIAGNOSTIC_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
