export const TEST_ACTION = "TEST_ACTION";

export const CHECK_AUTH_SESSION = "CHECK_AUTH_SESSION";
export const ON_AUTH_STATE_CHANGED_SIGNED_IN =
  "ON_AUTH_STATE_CHANGED_SIGNED_IN";
export const ON_AUTH_STATE_CHANGED_SIGNED_OUT =
  "ON_AUTH_STATE_CHANGED_SIGNED_OUT";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const INIT_DIAGNOSTIC = "INIT_DIAGNOSTIC";

export const FETCH_DIAGNOSTICS_REQUEST = "FETCH_DIAGNOSTICS_REQUEST";
export const FETCH_DIAGNOSTICS_SUCCESS = "FETCH_DIAGNOSTICS_SUCCESS";
export const FETCH_DIAGNOSTICS_FAILURE = "FETCH_DIAGNOSTICS_FAILURE";

export const POST_DIAGNOSTIC_REQUEST = "POST_DIAGNOSTIC_REQUEST";
export const POST_DIAGNOSTIC_SUCCESS = "POST_DIAGNOSTIC_SUCCESS";
export const POST_DIAGNOSTIC_FAILURE = "POST_DIAGNOSTIC_FAILURE";

export const SUBMIT_DIAGNOSTIC_STEP = "SUBMIT_DIAGNOSTIC_STEP";
export const SUBMIT_DIAGNOSTIC = "SUBMIT_DIAGNOSTIC";
