import * as ActionTypes from "../ActionTypes";
import firebase from "../../firebase";

export const testAction = () => {
  return {
    type: ActionTypes.TEST_ACTION
  };
};

export const initDiagnostic = () => {
  return (dispatch, getState) => {
    // Build new diagnostic
    const user = getState().auth.user;
    const now = new Date().toISOString();
    const diagnostic = {
      id: now,
      createdAt: now,
      startedAt: now,
      endedAt: null,
      authorId: user ? user.uid : null,
      authorEmail: user ? user.email : null,
      steps: []
    };

    return dispatch({
      type: ActionTypes.INIT_DIAGNOSTIC,
      item: diagnostic
    });
  };
};

/**
 * ==========
 * FETCH
 * ==========
 */
export const fetchDiagnostics = (force = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.FETCH_DIAGNOSTICS_REQUEST,
      force
    });

    const items = getState().diagnostics.items;
    if (!force && items && Object.keys(items).length > 0) {
      return dispatch(fetchDiagnosticsSuccess(items));
    }
    console.log("FIREBASE");
    return firebase
      .fetchDiagnostics()
      .then(items => {
        dispatch(fetchDiagnosticsSuccess(items));
      })
      .catch(err => {
        dispatch(fetchDiagnosticsFailure(err));
      });
  };
};

const fetchDiagnosticsSuccess = items => {
  return {
    type: ActionTypes.FETCH_DIAGNOSTICS_SUCCESS,
    items
  };
};
const fetchDiagnosticsFailure = error => {
  return {
    type: ActionTypes.FETCH_DIAGNOSTICS_FAILURE,
    error
  };
};

/**
 * ==========
 * POST
 * ==========
 */
export const postDiagnostic = diagnostic => {
  return dispatch => {
    dispatch({
      type: ActionTypes.POST_DIAGNOSTIC_REQUEST,
      item: diagnostic
    });

    return firebase
      .postDiagnostic(diagnostic)
      .then(id => {
        diagnostic.id = id;
        dispatch(postDiagnosticSuccess(diagnostic));
      })
      .catch(err => {
        dispatch(postDiagnosticFailure(err));
      });
  };
};

const postDiagnosticSuccess = item => {
  return {
    type: ActionTypes.POST_DIAGNOSTIC_SUCCESS,
    item
  };
};
const postDiagnosticFailure = error => {
  return {
    type: ActionTypes.POST_DIAGNOSTIC_FAILURE,
    error
  };
};

/**
 * ==========
 * SUBMIT
 * ==========
 */
export const submitDiagnosticStep = step => {
  return {
    type: ActionTypes.SUBMIT_DIAGNOSTIC_STEP,
    step
  };
};

export const submitDiagnostic = item => {
  return dispatch => {
    dispatch({
      type: ActionTypes.SUBMIT_DIAGNOSTIC,
      item
    });
    dispatch(postDiagnostic(item));
  };
};
