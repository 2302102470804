import defaultConfig from "./default";
import production from "./production";

let config = defaultConfig;

if (process.env.REACT_APP_SELFCITY_ENV === "PRODUCTION") {
  for (let key in production) {
    const val = production[key];

    if (val === Object(val)) {
      for (let key2 in val) {
        const val2 = val[key2];
        if (val2 === Object(val2)) {
        } else {
          config[key][key2] = val2;
        }
      }
    } else {
      config[key] = val;
    }
  }
}

config.isDev = () => {
  return process.env.REACT_APP_SELFCITY_ENV === "DEVELOPMENT";
};
export default config;
