import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ScreenLogin from "./screens/Login";
import ScreenLogout from "./screens/Logout";
import PrivateRoute from "./components/UI/PrivateRoute";
import DiagnosticScreen from "./screens/Diagnostic/List";
import ScreenDiagnosticForm from "./screens/Diagnostic/Form";
import Layout from "./components/UI/Layout";
import { connect } from "react-redux";

class App extends Component {
  render() {
    return (
      <BrowserRouter className="App">
        <Layout auth={this.props.auth}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to="/diagnostics" />}
            />
            <Route path="/login" component={ScreenLogin} />
            <PrivateRoute
              path="/diagnostics"
              exact
              component={DiagnosticScreen}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/diagnostics/form"
              component={ScreenDiagnosticForm}
              auth={this.props.auth}
            />
            <Route path="/logout" component={ScreenLogout} />
            <Redirect to="/login" />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};
export default connect(mapStateToProps)(App);
