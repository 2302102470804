import React from "react";
import { connect } from "react-redux";
import {
  initDiagnostic,
  submitDiagnostic,
  submitDiagnosticStep
} from "../../redux/actions/";
import { Redirect } from "react-router-dom";
import modelDiagnostics from "../../models/diagnostics";
import config from "../../config";

class DiagnosticForm extends React.Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      currentStageId: "0",
      currentStage: {},
      propositions: []
    };

    this.firstnameInput = React.createRef();
    this.lastnameInput = React.createRef();
    this.emailInput = React.createRef();
  }

  componentDidMount() {
    const firstStage = this.findStage(this.state.currentStageId);
    this.setState({ currentStage: firstStage });
    this.props.initDiagnostic();
  }

  debug = () => {
    const data = modelDiagnostics.buildFake();
    this.firstnameInput.current.value = data.customer.firstname;
    this.lastnameInput.current.value = data.customer.lastname;
    this.emailInput.current.value = data.customer.email;
  };

  findStage(id) {
    return this.props.stages[id];
  }

  findContent(id) {
    const arr = this.props.contents.filter(s => {
      return s.id === id;
    });
    const res = arr[0] && arr[0].content;

    return res;
  }

  selectProposition = proposition => {
    console.log(proposition);
    const nextStep = this.findStage(proposition.nextStepId);
    const propositions = this.state.propositions;

    propositions.push(proposition);

    this.props.submitDiagnosticStep(proposition);

    this.setState({
      currentIndex: this.state.currentIndex + 1,
      currentStageId: proposition.nextStepId,
      currentStage: nextStep,
      propositions
    });
  };

  /**
   * Example with
   * - prefix="customer"
   * - initialObject = {
   *     customerPhone:3,
   *     customerName: "Na",
   *     id: 123
   *    }
   *
   * @returns
   * {
   *  customer: {
   *    phone: 3,
   *    name: "Na"
   *  },
   *  id: 123
   * }
   */
  extractObject = (initialObject, prefix) => {
    const subOject = {};
    const newObject = {};

    const keys = Object.keys(initialObject);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = initialObject[key];
      const splits = key.split(prefix);
      if (splits.length === 2) {
        let subKey = splits[1];
        subKey = subKey[0].toLowerCase() + subKey.slice(1);
        subOject[subKey] = value;
      } else {
        newObject[key] = value;
      }
    }
    newObject[prefix] = subOject;
    return newObject;
  };

  onSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    const now = new Date().toISOString();
    let diagnostic = {
      ...this.props.diagnostic,
      endedAt: now,
      status: "CREATED"
    };

    for (let i = 0; i < form.length; i++) {
      const element = form.elements[i];
      if (["INPUT", "TEXTAREA"].indexOf(element.tagName) > -1) {
        let key = element.name;
        let value = element.value;

        diagnostic[key] = value;
      }
    }
    // Extract the customer fields
    diagnostic = this.extractObject(diagnostic, "customer");

    // Extract the address fields in customer
    diagnostic.customer = this.extractObject(diagnostic.customer, "address");
    this.props.submitDiagnostic(diagnostic);
  };

  render() {
    const step = this.state.currentStage;
    const isFinalStep = step.type === "final";
    let content = "null";
    if (isFinalStep) {
      content = this.findContent(step.id);
    }
    const diagnostic = this.props.diagnostic;
    return (
      <section>
        <h2>Nouveau diagnostic</h2>
        <form onSubmit={this.onSubmit} className="diagnostic-form">
          <ul>
            <li>Etape #{this.state.currentIndex}</li>
            <li>Id #{this.state.currentStageId}</li>
            <li>Date : {diagnostic.createdAt}</li>
            <li>Auteur : {diagnostic.authorEmail}</li>
          </ul>
          <div className="fieldset">
            <label htmlFor="source">Source</label>
            <input
              type="source"
              name="source"
              id="source"
              placeholder="Telephone, Messenger"
            />
          </div>
          <h3>Client</h3>
          <div className="fieldset">
            <label htmlFor="firstname">Prénom</label>
            <input
              type="text"
              name="customerFirstname"
              id="firstname"
              ref={this.firstnameInput}
            />
          </div>
          <div className="fieldset">
            <label htmlFor="lastname">Nom</label>
            <input
              type="text"
              name="customerLastname"
              id="lastname"
              ref={this.lastnameInput}
            />
          </div>

          <div className="fieldset">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="customerEmail"
              id="email"
              ref={this.emailInput}
            />
          </div>
          <div className="fieldset">
            <label htmlFor="phoneNumber">Téléphone</label>
            <input type="text" name="customerPhoneNumber" id="phoneNumber" />
          </div>

          <div className="fieldset">
            <label htmlFor="address">Adresse ligne 1</label>
            <input type="text" name="customerAddressLine1" id="address" />
          </div>
          <div className="fieldset">
            <label htmlFor="address">Adresse ligne 2</label>
            <input type="text" name="customerAddressLine2" id="address" />
          </div>
          <div className="fieldset">
            <label htmlFor="addressInfos">
              Autres infos (étage, # appt, digicode)
            </label>
            <input type="text" name="customerAddressInfos" id="addressInfos" />
          </div>
          <div className="fieldset">
            <label htmlFor="postalCode">Code postal</label>
            <input
              type="text"
              name="customerAddressPostalCode"
              id="postalCode"
            />
          </div>
          <div className="fieldset">
            <label htmlFor="city">Ville</label>
            <input type="text" name="customerAddressCity" id="city" />
          </div>

          <h3>Problème</h3>
          <div className="fieldset">
            <label htmlFor="equipment">Equipement</label>
            <textarea
              name="equipment"
              id="equipment"
              cols="60"
              rows="5"
              placeholder="marque..."
            />
          </div>
          <hr />
          <ul>
            {this.state.propositions.map(p => {
              return <li key={p.id}>{p.content}</li>;
            })}
          </ul>
          <h2 className="text-center">{step.title}</h2>
          <div className="flex flex-justify-evenly">
            {step.propositions &&
              step.propositions.map(p => {
                return (
                  <button
                    key={p.id}
                    className="mg-1 blue rounded big"
                    onClick={() => this.selectProposition(p)}
                  >
                    {p.content}
                  </button>
                );
              })}
          </div>
          {isFinalStep ? (
            <div>
              {step.priceMin}€ - {step.priceMax}€
              <p className="display-linebreak">{content}</p>
            </div>
          ) : (
            ""
          )}
          <hr />
          <div className="fieldset">
            <label htmlFor="notes">Notes</label>
            <textarea
              name="notes"
              id="notes"
              cols="60"
              rows="5"
              placeholder="Situation familiale, profession, ..."
            />
          </div>
          <div className="fieldset">
            <label htmlFor="howKnowUs">
              Comment a-t-il entendu parler du service ?
            </label>
            <input
              type="text"
              name="howKnowUs"
              id="howKnowUs"
              placeholder="pub, facebook ..."
            />
          </div>
          <div className="fieldset">
            <button
              type="submit"
              className="green w-100 pg-1"
              disabled={this.props.isFetching ? "disabled" : ""}
            >
              Envoyer
            </button>
          </div>
        </form>
        {this.props.itemHasBeenSubmitted ? <Redirect to="/diagnostics" /> : ""}
        {config.isDev ? <button onClick={this.debug}>DEBUG</button> : ""}
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    stages: state.stages.items,
    contents: state.stages.contents,
    diagnostic: state.diagnostics.current,
    isFetching: state.diagnostics.isFetching,
    itemHasBeenSubmitted: state.diagnostics.itemHasBeenSubmitted
  };
};
const mapDispatchToProps = dispatch => {
  return {
    initDiagnostic: () => dispatch(initDiagnostic()),
    submitDiagnostic: d => {
      dispatch(submitDiagnostic(d));
    },
    submitDiagnosticStep: step => {
      dispatch(submitDiagnosticStep(step));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticForm);
