import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  render() {
    const isConnected = this.props.auth.user !== null;
    return (
      <header>
        <nav className="nav">
          {isConnected ? (
            <PrivateHeaderLinks auth={this.props.auth} />
          ) : (
            <PublicHeaderLinks />
          )}
        </nav>
      </header>
    );
  }
}

const PublicHeaderLinks = () => (
  <ul className="no-style inline">
    <li>
      <Link to="/">Accueil</Link>
    </li>
    <li>
      <Link to="/login">Se connecter</Link>
    </li>
  </ul>
);

const PrivateHeaderLinks = ({ auth }) => (
  <ul className="no-style inline">
    <li>
      <Link to="/">Accueil</Link>
    </li>
    <li>
      <Link to="/diagnostics">Diagnostics</Link>
    </li>
    <li>{auth.user.email}</li>
    <li>
      <Link to="/logout">Se déconnecter</Link>
    </li>
  </ul>
);

export default Header;
