const fromNormalizedtoArray = items => {
  const keys = Object.keys(items);
  const array = keys.map(k => {
    return { id: k, ...items[k] };
  });
  return array;
};
const fromArraytoNormalized = array => {
  const result = {};
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const id = item.id;
    result[id] = item;
  }
  return result;
};
const getRandomItem = items => {
  const length = items.length;
  const idx = Math.floor(Math.random() * length);
  return items[idx];
};
const buildFake = () => {
  const firstnames = [
    "John",
    "Maxime",
    "Baptiste",
    "Murielle",
    "Marie-Line",
    "Amélie"
  ];
  const lastnames = ["Dupond", "Durant", "Martin", "Buisson", "Harry", "Morel"];
  return {
    customer: {
      firstname: getRandomItem(firstnames),
      lastname: getRandomItem(lastnames),
      email: "unknown@example.com",
      phoneNumber: "0102030405",
      address: {
        line1: "18 allées Jean Jaurès",
        line2: "",
        postalCode: "31000",
        city: "Toulouse"
      }
    }
  };
};

export default { fromNormalizedtoArray, fromArraytoNormalized, buildFake };
