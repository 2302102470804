export default {
  firebase: {
    apiKey: "AIzaSyDR0Bu92YOkbOCBfaPU7anbCy_Hq5EqcUY",
    authDomain: "plombiers-toulousains.firebaseapp.com",
    databaseURL: "https://plombiers-toulousains.firebaseio.com",
    projectId: "plombiers-toulousains",
    storageBucket: "plombiers-toulousains.appspot.com",
    messagingSenderId: "797492534168"
  },
  db: {
    rootCollection: "ENVIRONMENTS",
    envCollection: "DEVELOPMENT"
  }
};
